@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url(./assets/fonts/AvenirNext-UltraLight.otf);
}
@font-face {
  font-family: "AvenirRegular";
  src: local("AvenirRegular"), url(./assets/fonts/AvenirNextLTPro-Regular.otf);
}
html{
  overflow: hidden;
}

@keyframes backgroundScroll {
  0% { 
    -webkit-transform: translateX(0);  
  }
  /* 50% { 
    -webkit-transform: translateX(-2%);  
  } */
  100% { 
    -webkit-transform: translateX(-3%);  
  }
}


.animate{
  animation: backgroundScroll 10s linear infinite;
}

body {
  margin: 0;
  font-family: "Avenir";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: white;

}

.title {
  color: white;
  font-size: 50px;
}
.tulum {
  color: white;
  font-size: 30px;
}

a {
  text-decoration: none;
  color: inherit;
}
.instagram:hover {
  text-decoration: underline;
  text-decoration-color: white;
}

.test {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.imgContainer {
  float: left;
}

ul {
  display: flex;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  flex-wrap: wrap;
}

li {
  height: 40vh;
  flex-grow: 1;
  padding: 12.5px;
}

li:last-child {
  flex-grow: 10;
}

/* .grid img {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
} */

/* video {
  max-height: 100%;
  min-width: 100%;
} */
.fullVideo {
  object-fit: cover;
}
.video {
  max-height: 100%;
  min-width: calc(100% - 70px);
  margin-top: 10px;
  object-fit: cover;
  align-self: center;
  padding: 0px;

  margin: 35px;
}
@media (max-aspect-ratio: 1/1) {
  li {
    height: 30vh;
  }
}

@media (max-height: 480px) {
  li {
    height: 80vh;
  }
}

@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  ul {
    flex-direction: row;
  }

  li {
    height: auto;
    width: 100%;
  }
  /* img {
    width: 100%;
    max-height: 75vh;
    min-width: 0;
  } */
}

.center {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.bgimage {
  max-width: calc(100% - 80px) !important;
  margin-bottom: 50px;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(158, 158, 158);
}

.onHover:hover {
  /* text-decoration: underline; */
  transition: 0.4s;
  /* text-decoration-style: solid; */
  border-right: 1px solid;
}
.onHover {
  /* text-decoration-color: transparent; */
  /* border-bottom: 1px transparent; */
  /* margin-left: 17px; */
  /* border-left: 1px solid; */
  padding-left: 10px;
  padding-right: 10px;
  /*margin-bottom: 10px; */
  /* margin: 0px; */
  /* margin-bottom: 10px; */
}

video:focus { outline: none; }

.playButton{
  /* opacity: 0.4; */
    -webkit-animation: FADE-IN 3s infinite;
  -moz-animation:    FADE-IN 3s infinite;
  -o-animation:      FADE-IN 3s infinite;
  animation:         FADE-IN 3s infinite;
}


@-webkit-keyframes FADE-IN {
  0%   { opacity: 0.2; }
  50%   { opacity: 0.8; }
  100% { opacity: 0.2; }
}
@-moz-keyframes FADE-IN {
  0%   { opacity: 0.2; }
  50%   { opacity: 0.8; }
  100% { opacity: 0.2; }
}
@-o-keyframes FADE-IN {
  0%   { opacity: 0.2; }
  50%   { opacity: 0.8; }
  100% { opacity: 0.2; }
}
@keyframes FADE-IN {
  0%   { opacity: 0.2; }
  50%   { opacity: 0.8; }
  100% { opacity: 0.2; }
}

#breathing-button {
  -webkit-animation: breathing 5s ease-out infinite normal;
  animation: breathing 3s ease-out infinite normal;
  -webkit-font-smoothing: antialiased;
  }


@-webkit-keyframes breathing {
  0% { 
    -webkit-transform: scale(0.9);
   transform: scale(0.9); 
 }
 5% { 
    -webkit-transform: scale(0.91);
   transform: scale(0.91); 
 }
 10% { 
    -webkit-transform: scale(0.92);
   transform: scale(0.92); 
 }
 15% { 
    -webkit-transform: scale(0.93);
   transform: scale(0.93); 
 }
 20% { 
    -webkit-transform: scale(0.94);
   transform: scale(0.94); 
 }
 25% { 
    -webkit-transform: scale(0.95);
   transform: scale(0.95); 
 }
 30% { 
    -webkit-transform: scale(0.96);
   transform: scale(0.96); 
 }
 35% { 
    -webkit-transform: scale(0.97);
   transform: scale(0.97); 
 }
 40% { 
    -webkit-transform: scale(0.98);
   transform: scale(0.98); 
 }
 45% { 
    -webkit-transform: scale(0.99);
   transform: scale(0.99); 
 }
 50% { 
    -webkit-transform: scale(1.0);
   transform: scale(1.0); 
 }
 55% { 
    -webkit-transform: scale(0.99);
   transform: scale(0.99); 
 }
 60% { 
    -webkit-transform: scale(0.98);
   transform: scale(0.98); 
 }
 65% { 
    -webkit-transform: scale(0.97);
   transform: scale(0.97); 
 }
 70% { 
    -webkit-transform: scale(0.96);
   transform: scale(0.96); 
 }
 75% { 
    -webkit-transform: scale(0.95);
   transform: scale(0.95); 
 }
 80% { 
    -webkit-transform: scale(0.94);
   transform: scale(0.94); 
 }
 85% { 
    -webkit-transform: scale(0.93);
   transform: scale(0.93); 
 }
 90% { 
    -webkit-transform: scale(0.92);
   transform: scale(0.92); 
 }
 95% { 
    -webkit-transform: scale(0.91);
   transform: scale(0.91); 
 }
 100% { 
    -webkit-transform: scale(0.9);
   transform: scale(0.9); 
 }
}

@keyframes breathing {
  0% { 
    -webkit-transform: scale(0.9);
   -ms-transform: scale(0.9);
   transform: scale(0.9); 
 }
 5% { 
    -webkit-transform: scale(0.91);
   -ms-transform: scale(0.91);
   transform: scale(0.91); 
 }
 10% { 
    -webkit-transform: scale(0.92);
   -ms-transform: scale(0.92);
   transform: scale(0.92); 
 }
 15% { 
    -webkit-transform: scale(0.93);
   -ms-transform: scale(0.93);
   transform: scale(0.93); 
 }
 20% { 
    -webkit-transform: scale(0.94);
   -ms-transform: scale(0.94);
   transform: scale(0.94); 
 }
 25% { 
    -webkit-transform: scale(0.95);
   -ms-transform: scale(0.95);
   transform: scale(0.95); 
 }
 30% { 
    -webkit-transform: scale(0.96);
   -ms-transform: scale(0.96);
   transform: scale(0.96); 
 }
 35% { 
    -webkit-transform: scale(0.97);
   -ms-transform: scale(0.97);
   transform: scale(0.97); 
 }
 40% { 
    -webkit-transform: scale(0.98);
   -ms-transform: scale(0.98);
   transform: scale(0.98); 
 }
 45% { 
    -webkit-transform: scale(0.99);
   -ms-transform: scale(0.99);
   transform: scale(0.99); 
 }
 50% { 
    -webkit-transform: scale(1.0);
   -ms-transform: scale(1.0);
   transform: scale(1.0); 
 }
 55% { 
    -webkit-transform: scale(0.99);
   -ms-transform: scale(0.99);
   transform: scale(0.99); 
 }
 60% { 
    -webkit-transform: scale(0.98);
   -ms-transform: scale(0.98);
   transform: scale(0.98); 
 }
 65% { 
    -webkit-transform: scale(0.97);
   -ms-transform: scale(0.97);
   transform: scale(0.97); 
 }
 70% { 
    -webkit-transform: scale(0.96);
   -ms-transform: scale(0.96);
   transform: scale(0.96); 
 }
 75% { 
    -webkit-transform: scale(0.95);
   -ms-transform: scale(0.95);
   transform: scale(0.95); 
 }
 80% { 
    -webkit-transform: scale(0.94);
   -ms-transform: scale(0.94);
   transform: scale(0.94); 
 }
 85% { 
    -webkit-transform: scale(0.93);
   -ms-transform: scale(0.93);
   transform: scale(0.93); 
 }
 90% { 
    -webkit-transform: scale(0.92);
   -ms-transform: scale(0.92);
   transform: scale(0.92); 
 }
 95% { 
    -webkit-transform: scale(0.91);
   -ms-transform: scale(0.91);
   transform: scale(0.91); 
 }
 100% { 
    -webkit-transform: scale(0.9);
   -ms-transform: scale(0.9);
   transform: scale(0.9); 
 }
}

